/* eslint-disable max-len */
export default {
  hu: {
    siteName: window.location.hostname.replace(/^www\./i, ''),
    // dataPrivacyLink: '/dokumentumok/adatkezelesi_tajekoztato.pdf',
    dataPrivacyLink: null,
    simpleHeadline: `Cookie-k a {{i18n.siteName}} oldalain.`,
    simpleDisclaimer: (
      `A legjobb felhasználói élmény elérése érdekében a weboldalon cookie-kat használunk.
      Ezek hozzájárulnak az oldalaink működésének biztosításához, megkönnyítik az oldalak használatát.
      A cookie-k szükségesek a weblapon végzett műveletek nyomon követése és a szolgáltatások javítása,
      valamint a releváns ajánlatok megjelenítése érdekében. Valamennyi Cookie nélkülözhetetlen
      szolgáltatásaink biztosításához, egyéb cookie-k – ideértve a harmadik féltől származóakat –,
      kikapcsolhatók, azonban nélkülük oldalunk nem működik teljes értékűen, és személyre szabottan.
      Az "Ajánlott cookiek elfogadása" gombra kattintva hozzájárul a cookie-k teljes körű használatához.
    `
    ),
    advancedHeadline: 'Az oldal cookie beállításai.',
    advancedDisclaimer: (
      'Kérjük, válassza ki, hogy oldalunk milyen cookie-kat használjon, az alábbiak szerint:'
    ),
    keepTheSettingsAndClose: 'Beállítások megtartása',
    table_note_3rdparty: (
      `
      <p>
        <small>
        (●) A "harmadik fél" oszlopban megjelölt „cookie”-k nem a honlap üzemeltetője
        által felügyelt kiszolgálószerverekről származnak, így a honlap üzemeltetőjének
        kontrollja ezen „cookie”-k felett technikai értelemben csak részleges. Ezzel
        kapcsolatban további információk olvashatók jelen tájékoztató harmadik
        féltől származó „cookie”-król szóló részében.
        </small>
      </p>
      `
    ),
    cookiePolicy: (
      `
        <article>

          <h1>A {{i18n.siteName}} „cookie”-kkal kapcsolatos adatkezelési irányelvei.</h1>

          <h2>Mi a "Cookie"?</h2>

          <p>
            A „cookie” (más néven „süti”, továbbiakban „cookie”) egy kis szöveges fájl,
            amely egy adott honlaphoz, illetve az azt megjelenítő böngészőhöz köthetően a
            felhasználói interakcióival kapcsolatos olyan információkat tárol, amelyeket a
            honlap készítője/készíttetője meghatároz. Ilyenek lehetnek például a honlappal
            kapcsolatos beállítások, a megjelenített tartalom felhasználói érdeklődésnek és
            igényének megfelelő kialakítása stb.
          </p>

          <p>
            Az {{i18n.siteName}} honlapon alkalmazott „cookie”-k kategóriájuktól függően lehetnek jogi
            megfogalmazással szigorúan szükséges, gyakorlatban az oldalon automatikusan használt
            „cookie”-k vagy olyanok, amelyek alkalmazásához előzetesen kifejezetten felhasználói
            hozzájárulás szükséges. Kategóriájuktól függően ezek lehetnek funkcionális vagy
            teljesítmény vagy marketing „cookie”-k.
          </p>

          <p>
            Ma már szinte minden honlap alkalmaz „cookie”-kat, amelyek célja alapvetően az, hogy a
            felhasználó részéről történő ismételt holnaplátogatások felismerhetők legyenek és ennek
            segítségével a felhasználói élmény javítható legyen. Ilyen lehet többek között a
            felhasználó által korábban már megtett beállítások - pl. a „cookie” beállítások –
            megjegyzése, hogy ennek megtételére a felhasználó legközelebbi látogatásakor már ne
            legyen szükség.
          </p>

          <h3>A cookie-k használatának általános engedélyezése/tiltása.</h3>

          <p>
            A legtöbb böngésző beállításai lehetőséget adnak arra, hogy a „cookie”-k alkalmazása
            általánosan engedélyezett vagy tiltott legyen a honlapok megtekintésekor. A korszerű
            böngészők általában a böngésző-beállításokon keresztül biztosítják a „cookie”-k
            alkalmazásának szabályozását. Bővebb információ különböző böngészők
            cookie-beállításairól a lap alján vagy a
            <a aria-labelledby="dwcc_more_info_on_youroc" href="http://www.youronlinechoices.com/hu/" target="_blank">www.youronlinechoices.com</a>
            weboldalon is található.
          </p>

          <p>
            Fontos tudni, hogy a „cookie” alkalmazásának általános formában történő tiltása
            a böngésző-beállításokon keresztül azt is eredményezheti, hogy a honlap interaktív
            funkciói részben vagy egészben nem lesznek használhatók. Ezért célszerűbb mindig az
            adott honlap által biztosított „cookie” beállítási lehetőségekkel szabályozni a
            honlaphoz kapcsolódó „cookie”-k alkalmazását.
          </p>

          <p>
            Első látogatáskor a {{i18n.siteName}} honlaphoz kapcsolódóan a felhasználó a böngészőablak alján
            megjelenő „Cookie-k elfogadása” gombbal járulhat hozzá a „cookie”-k alkalmazásához.
            A hozzájárulás megadása előtt a „Részletes beállítások” hivatkozáson szabályozható,
            hogy mely kategóriába tartozó „cookie”-k alkalmazását engedélyezi a felhasználó.
            A „cookie-k” alkalmazásának engedélyezését követően a beállítások 1 naptári hónapig
            megjegyzésre kerülnek a böngészőben, de bármikor módosíthatók az oldal láblécében
            megtalálható „Cookie beállítások” menüpontban.
          </p>

          <p>
            <small>Megjegyzés: megtörténhet, hogy egy harmadik féltől származó „cookie” még a felhasználó
            által történő elutasítást megelőzően eltárolásra került a böngészőeszközön
            (pl. korábbi engedélyezés által stb.). Technikai okokból ilyen esetben a honlap által
            biztosított beállításokkal történő „cookie” engedélyezés folyamata során csak azok
            a „cookie”-k lesznek törölhetők, amelyek nem harmadik féltől származtak.
            A már a böngészőeszközre kerül harmadik féltől származó „cookie”-k esetén
            azok törlése a böngésző által biztosított adatkezelési beállításoknál lesz lehetséges.</small>
          </p>

          <p>
            Az alábbi táblázatok bemutatják a {{i18n.siteName}} honlapon alkalmazott „cookie”-kat,
            illetve ismertetik azok használatának célját. Az információkat rendszeresen frissítésre
            kerülnek, azonban mégis előfordulhat, hogy a tájékoztatók tartalma nincs szinkronban
            a programkódokban található információkkal. Amennyiben a felhasználó ilyen eltérést
            tapasztal, úgy nagyban segítheti a honlap készítőjének/készíttetőjének tájékoztatási
            kötelezettségét, ha ezeket az információkat a tudomásukra hozza.
          </p>



          <h2>A honlap alapműködéséhez szigorúan szükséges „cookie”-k, melyekhez nincs szükség a beleegyezésére:</h2>

          <p>
            A jogszabályok felhasználói hozzájárulás nélkül olyan „cookie”-k alkalmazását
            teszik lehetővé, amelyek hiányában technikai értelemben nem lehetne megfelelően
            és teljes értékűen használni a honlapot. Ilyen funkciók lehetnek például a honlap
            és a kiszolgálószerver közötti adatkommunikáció során a kapcsolatot szolgáló
            munkamenet azonosító „cookie” vagy a magukkal a „cookie”-k engedélyezésével
            kapcsolatban megtett felhasználói beállítások megjegyzése.
          </p>

          <h3>SZIGORÚAN SZÜKSÉGES „COOKIE”-K</h3>

          <p>
            A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:
          </p>


          {{components.cookie_table_cookies_essential}}


          <h2>Előzetes felhasználói hozzájárulást igénylő „cookie”-k:</h2>

          <p>
            Azon „cookie”-k esetében, amelyek jogi értelemben nem feltétlenül szükségesek a honlap
            működéséhez, elengedhetetlenül szükség van arra, hogy alkalmazásukhoz a felhasználó
            kifejezetten hozzájáruljon.
          </p>
          <p>
            Amennyiben a felhasználó nem járul hozzá egészében ezen „cookie”-k alkalmazásához az
            oldalon, úgy azt is elfogadja, hogy a hozzájárulásának hiányában a honlap működése
            nem minden tekintetben lesz a készítő/készíttető szándékainak megfelelően biztosított.
            Ugyanakkor a későbbiekben a felhasználónak lehetősége van bármikor és minden további
            feltétel nélkül a hozzájárulást igénylő „cookiek”-k engedélyezésével kapcsolatos
            korábbi döntését a honlapon erre vonatkozóan biztosított beállítási lehetőségekkel
            megváltoztatni.
          </p>
          <p>
            Az előzetes felhasználói hozzájárulást igénylő „cookie”-k három kategóriába sorolhatók:
          </p>
          <ul aria-label="Hozzájárulást igénylő „cookie” kategóriák">
            <li>funkcionális „cookie”-k</li>
            <li>teljesítmény „cookie”-k</li>
            <li>marketing „cookie”-k</li>
          </ul>

          <p>
            A fentekben említett „cookie”-k alkalmazása során gyűjtött információk a felhasználó
            beazonosításra nem alkalmasak, személyhez nem köthetők, bizonyos esetekben kizárólag
            összegezve kerülnek felhasználásra. Ezen „cookie”-k által hozzáférhető információk
            feldolgozása jellemzően automatizáltan történik és a honlap fejlesztését,
            illetve a teljesítményének növelését, egyben ezek által a felhasználói élmény
            javítását szolgálják.
          </p>


          <h3>FUNKCIONÁLIS COOKIE-K.</h3>

          <p>
            A funkcionális cookie-k lehetővé teszik, hogy a honlapon a felhasználó által
            megadott egyes információk elmentésre kerüljenek, hogy az oldal jobb, személyre
            szabottabb funkciókat kínálhasson (például az oldalon kiválasztott megjelenítési
            nyelv stb.). Továbbá az ilyen jellegű „cookie”-k segítik különböző állapotok
            megőrzését az oldalon történő navigáció során, mint pl. a videólejátszó
            beállított hangereje, a lejátszás aktuális pozíciója, stb.
          </p>

          <p>A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:</p>

          {{components.cookie_table_cookies_functional}}

          {{i18n.table_note_3rdparty}}

          <h3>TELJESÍTMÉNY COOKIE-K.</h3>

          <p>
            A teljesítmény „cookie”-k a honlap statisztikai méréseihez gyűjtenek információkat
            arról, hogy a látogatók hogyan, milyen felhasználói szokások mellett és beállításokkal
            használják az oldalt. Ilyen adatok lehetnek például, hogy a felhasználó mely oldalakat
            nyitotta meg vagy az oldal mely funkcióit használta a leggyakrabban, mennyi időt
            töltött az oldalon, kapott-e esetleg hibaüzeneteket a használat során stb.
          </p>

          <p>A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:</p>

          {{components.cookie_table_cookies_performance}}
          {{i18n.table_note_3rdparty}}


          <h3>MARKETING COOKIE-K.</h3>

          <p>
            A marketing „cookie”-k gyűjtőnéven azok a „cookie”-k, amelyek első sorban a
            felhasználó érdeklődéséhez köthető hirdetések célba juttatását, célcsoportkeresést
            és a reklámozás fejlesztését szolgálják. Segítségükkel korlátozni is lehet a
            felhasználó számára történő hirdetésmegjelenések számát, illetve mérni lehet
            az online reklámkampányok hatékonyságát. Gyakran összekapcsolva működnek más,
            harmadik fél által biztosított honlapszolgáltatásokkal.
          </p>
          <p>
            A marketing „cookie”-k által gyűjtött adatok alkalmasak annak megállapítására,
            hogy a felhasználó meglátogatott egy adott honlapot és ez az információ megosztásra
            kerülhet harmadik féllel, például online hirdetési felületszolgáltatókkal.
          </p>

          <p>A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:</p>

          {{components.cookie_table_cookies_marketing}}
          {{i18n.table_note_3rdparty}}

          <br />

          <h2>A harmadik fél által biztosított „cookie”-kra vonatkozó kiegészítő információk:</h2>

          <br />

          {{components.thirdparty_table}}


          <h3>Honlapba ágyazott közösségi médiához kapcsolódó szolgáltatások</h3>

          <p>
            Egy honlap a közösségi médiaplatformok (pl. Facebook, LinkedIn, Twitter stb.) által
            biztosított, oldalba ágyazható megosztási vagy más felhasználói interakció lehetőségét
            biztosító funkcióblokkot („share” vagy „megosztás” gomb stb.) tartalmazhat, amelyek
            lehetővé teszik, hogy a felhasználótól/felhasználóról információk jussanak el a
            funkcióblokkhoz köthető médiaplatformra/médiaplatformhoz. A funkcióblokkok használata
            során esetlegesen előfordulhat, hogy a médiaplatform a honlap készítője/készíttetője
            tudtán kívül „cookie”-kat helyez el a felhasználó böngészőeszközén. Ezek a „cookie”-k
            semmilyen formában nem kapcsolódnak a honlap készítőjéhez/készíttetőjéhez, akinek ezek
            működésére, adatgyűjtésére vonatkozóan semmilyen ráhatása nincs. Ezekkel a „cookie”-kal
            kapcsolatban a harmadik fél által biztosított „cookie” tájékoztatók tartalmazhatnak
            adatkezelési információt a felhasználó számára.
          </p>

          <h2>Egyéb információk</h2>
          <p>
            <b>
              <span id="dwcc_most_used_browsers_desc">A legelterjedtebb böngészők „cookie” beállítási</span> lehetőségeivel kapcsolatban az alább
              felsorolt böngészők megnevezésére kattintva érhető el bővebb információ:
            </b>
          </p>
          <ul aria-labelledby="dwcc_most_used_browsers_desc">
            <li><a href="https://support.apple.com/hu-hu/guide/safari/sfri11471/11.0/mac/10.13" target="_blank">Apple Safari beállítások</a></li>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=hu" target="_blank">Google Chrome beállítások</a></li>
            <li><a href="https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer beállítások</a></li>
            <li><a href="https://support.microsoft.com/en-ie/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank">Microsoft Edge beállítások</a></li>
            <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera beállítások</a></li>
            <li><a href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn" target="_blank">Mozilla Firefox beállítások</a></li>
          </ul>

          <br />

          <p>
            <b>Általánosan a <span id="dwcc_more_info_on_youroc">„cookie”-kkal kapcsolatos további átfogó és részletes információk</span>
            találhatók az alábbi hivatkozáson elérhető online adatvédelmi és viselkedés
            alapú hirdetési útmutatóban:</b>
          </p>

          <p><a aria-labelledby="dwcc_more_info_on_youroc" href="http://www.youronlinechoices.com" target="_blank">www.youronlinechoices.com</a></p>

          <p>Ilyen információk például, hogy miként tekinthetők meg és rendezhetők,
            illetve törölhetők a böngészőben elmentett „cookie”-k stb.
          </p>

        </article>
      `
    ),
    cat_on: 'Be',
    cat_off: 'Ki',
    btn_accept: 'Ajánlott cookie-k elfogadása',
    btn_reject: 'Visszautasít',
    btn_close: '+', // ×
    btn_close_tile: 'Bezárás',
    btn_privacy: 'Adatkezelési tájékoztató',
    btn_cookies: 'Cookie tájékoztató',
    btn_privacy_advanced: 'Adatkezelési tájékoztató',
    btn_save_advanced: 'Beállítás elfogadása',
    btn_acceptall_advanced: 'Mindent elfogad',
    btn_advanced: 'Részletes beállítások',
    icon_category_label: "Kategória leírása - enter vagy szóköz gomb aktiválja a kategóriát",
    icon_close_label: "Bezárás",
    inp_cookie_level: 'Engedélyezett cookie-k',
    table_name: 'Cookie név',
    table_cookies: 'Cookie-k',
    table_provider: 'Szolgáltató',
    table_domain: 'Domain',
    table_thirdparty: 'Harmadik fél',
    table_purpose: 'Felhasználás',
    table_source: 'Forrás',
    table_type: 'Típus',
    table_expiry: 'Lejárat',
    table_link: 'Hivatkozás',
    table_description: 'Leírás',
    table_link_opt_out: 'Leiratkozás',
    table_link_cookie_policy: 'Cookie-k',
    table_link_data_policy: 'Adatkezelés',
    table_empty_category: 'Jelenleg nincs ebbe a kategóriába besorolt cookie.',
    cookie_essential_label: 'Szigorúan szükséges',
    cookie_essential_desc: 'Ezen cookie-k biztosítják a weboldal megfelelő működését és segítik annak használatát.',
    cookie_essential_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul aria-label="A kategória az alábbiakat teszi lehetővé">
        <li>Munkamenet kezelés (session)</li>
      </ul>
      `
    ),
    cookie_functional_label: 'Funkcionális',
    cookie_functional_desc: 'Ezen cookie-k segítenek megőrizni a látogató preferenciáit, pl. oldalak navigációja között megőrzik az állapotot.',
    cookie_functional_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul aria-label="A kategória az alábbiakat teszi lehetővé">
        <li>Munkamenet kezelés</li>
        <li>Beállítások, pl. a választott nyelv megjegyzésére</li>
      </ul>
      `
    ),
    cookie_performance_label: 'Teljesítmény',
    cookie_performance_desc: 'Ezen cookie-k úgy járulnak hozzá a weboldal továbbfejlesztéséhez, hogy elemzési célú információszerzést tesznek lehetővé.',
    cookie_performance_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul aria-label="A kategória az alábbiakat teszi lehetővé">
        <li>Munkamenet kezelés</li>
        <li>Beállítások megjegyzésére</li>
        <li>Oldal használatának elemzése jobb tartalmak előállítása érdekében</li>
      </ul>
      `
    ),
    cookie_marketing_label: 'Analitikai és marketing',
    cookie_marketing_desc: 'Ezen cookie-k a weboldal felhasználói szintű viselkedési adatait gyűjtik, hogy releváns ajánlatokat kínálhassunk.',
    cookie_marketing_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul aria-label="A kategória az alábbiakat teszi lehetővé">
        <li>Munkamenet kezelés</li>
        <li>Beállítások megjegyzésére</li>
        <li>Oldal használatának elemzése jobb tartalmak előállítása érdekében</li>
        <li>Tartalmak és marketing relevanciájuk alakítása érdeklődés alapján</li>
      </ul>
      `
    )
  }
};
